
import React, { Component, useRef, useEffect, useState } from 'react';
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import { Link } from 'react-router-dom'  
import Vimeo from '@u-wave/react-vimeo'; 
 
class WelcomeTemplate extends Component {
 
  constructor(props) {
    super(props);
  }
  
 
 

  render() {

    let unlockedChars = JSON.parse(localStorage.getItem("unlockedCharacters"));
    if (unlockedChars) {
      unlockedChars = unlockedChars.length;
    } else {
      unlockedChars = 0;
    } 
    return (
      <div id="welcome" class="vh-100 py-5">
        <div class="container text-center">
          <div class="row">
            <div class="col-12 mt-5"> 
              <p>Welcome to the Time Travel Agency</p>
              <h1 class="neon mb-3" data-text="U">TE<span class="flicker-slow">S</span>T <span class="flicker-fast">T</span>UB<span class="flicker-slow">ES</span> & T<span class="flicker-fast">I</span>ME T<span class="flicker-slow">RA</span>VEL</h1>

              <p>Watch the introduction below to meet your guide & get your bearings.</p>

               
              <p><Vimeo
                video="https://player.vimeo.com/video/591475092?h=b84f9d1c14&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479"
                width="300"
                autoplay="false"
              />
              </p>

              <p className='my-2'>When you're all set, click Start Adventure & track down the pins on the map to unlock more films</p>

              {unlockedChars > 0 ? (
                <Link class="btn btn-primary" to="/story">Continue Adventure</Link>
              ) : (
               <Link class="btn btn-primary" to="/story">Start Adventure</Link>
              )}

              <p class="mt-3">When you are done, please take 2 minutes to help us improve Test Tubes & Time Travel and other similar events</p>
              <a href="https://www.menti.com/alzqvoputgih" target="_blank" class="btn btn-primary">Take Survey</a>
              <p class="mt-5">v.1.8.0</p>
            </div>
          </div>


        </div>
      </div>
    )
  }
}


const mapStateToProps = (state, ownProps) => {

 
}

export default withRouter(connect(mapStateToProps, {
   
})(WelcomeTemplate))

 