const dev = { 
};

const prod = { 
};


global.config = {
   MAP_KEY: "pk.eyJ1IjoiYmF0aHBldSIsImEiOiJja3RsbzBuNWwwaWtjMnZtam5ic2lyNWdyIn0.pnp38afCdph9FQjcPn0gTQ"
};


// Default to dev if not set  pk_live_HtdzO35fojjONfkBK5VtAzzf
const config = process.env.REACT_APP_STAGE === 'prod'
  ? prod
  : dev;

export default {
  // Add common config values here
  MAX_ATTACHMENT_SIZE: 5000000,
  ...config
}; 