import React, { Component } from 'react'; 
import { Link } from 'react-router-dom' 
import UnlockModalContent from '../../components/UnlockModalContent/UnlockModalContent'; 
export default class UnlockModal extends Component {

  componentWillMount() {
    var page = this;
   
    var unlockedCharacters = JSON.parse(localStorage.getItem("unlockedCharacters") || "[]");
    this.setState({ unlockedCharacters: unlockedCharacters });
  }

  displayUnlockButtons() {
    const {unlocks, stories, unlockedNumber} = this.props;
   
    
      return unlocks.map((storyId, index) => (

        <>
        {stories[storyId] && stories[storyId].order <= unlockedNumber ? (
          <UnlockModalContent setUnlockedCharacters={this.props.setUnlockedCharacters} type="story" content={stories[storyId]}/>
        ) : (
          <UnlockModalContent setUnlockedCharacters={this.props.setUnlockedCharacters} type="story" content={stories[storyId]}/>
        )}
        </>
        
      ));
 
    
 
  }

  displayUnlockButtonsResearch() {
    const {researchUnlocks, researchers} = this.props;
 
      return researchUnlocks.map((storyId, index) => (
 
        <UnlockModalContent type="researchers" content={researchers[storyId]}/>
     
        
      ));
 
    
 
  }

  displayGame() {
    const {unlocks, stories, unlockedNumber} = this.props;
    var unlockedCharacters = JSON.parse(localStorage.getItem("unlockedCharacters") || "[]");

    if (Object.keys(this.props.stories).length != 0 && (Object.keys(this.props.stories).length == unlockedCharacters.length)) {
      return (
        <div>
          You have found all the letters! <br/><br/>
          <Link class="btn btn-primary" to="/game">Guess My Name</Link> 
        </div>
      );
    }
  }
 
  render() {

    const {unlocks} = this.props;
    
    return (

      <div id="unlockAlert">{this.displayGame()} {this.displayUnlockButtons()} {this.displayUnlockButtonsResearch()}</div>

    ) 
  }
}
